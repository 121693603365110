<template>
  <v-card
    outlined
  >
    <v-card-text>
      <manage-project-participant-slots-item
        v-for="slot of project.slots"
        :key="slot.id"
        ref="slot"
        :event="event"
        :project="project"
        :item="slot"
        @input="updateDirtyAvailable"
      />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="info"
        :disabled="!dirtyAvailable"
        depressed
        @click="updateAll"
      >
        Save All
      </v-btn>
      <v-spacer />
      <v-btn
        color="success"
        :loading="loading.create"
        :disabled="loading.create || project.slots.length >= event.max_participants"
        depressed
        @click="createMutation"
      >
        <v-icon
          class="mx-0"
          left
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ManageProjectParticipantSlotsItem from './ManageProjectParticipantSlotsItem'

export default {
  name: 'ManageProjectParticipantSlots',
  components: { ManageProjectParticipantSlotsItem },
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: {
      create: false
    },
    dirtyAvailable: false
  }),
  methods: {
    async createMutation () {
      this.loading.create = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createProjectParticipantSlot').default,
          variables: { project_id: this.project.id, name: 'Anyone' },
          update: (store, { data: { createProjectParticipantSlot } }) => {
            const data = store.readQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug } })
            const currentlyCachedProject = this._.find(data.projects, { id: this.project.id })
            currentlyCachedProject.slots = this._.unionBy(currentlyCachedProject.slots, [createProjectParticipantSlot], 'id')
            store.writeQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug }, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createProjectParticipantSlot: {
              __typename: 'ProjectParticipantSlot',
              id: -1,
              name: 'Anyone',
              user: null
            }
          }
        })
        this.$emit('update')
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.create = false
    },
    updateDirtyAvailable () {
      if (!this.$refs.slot) this.dirtyAvailable = false
      else this.dirtyAvailable = this._.some(this.$refs.slot.map(ref => ref.isDirty))
    },
    updateAll () {
      if (!this.$refs.slot) return
      for (const ref of this.$refs.slot) {
        if (ref.isDirty) ref.updateMutation()
      }
    }
  }
}
</script>
