import gql from 'graphql-tag'
import ProjectAllFields from './fragments/ProjectAllFields'

export default gql`
  mutation createProject ($event_id: Int!, $name: String!) {
    createProject (event_id: $event_id, name: $name) {
      ...ProjectAllFields
    }
  }
  ${ProjectAllFields}
`
