import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  subscription projectParticipantSlotsUpdated ($project_id: Int!) {
    projectParticipantSlotsUpdated (project_id: $project_id) {
      id
      name
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
