import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  mutation updateProjectParticipantSlot ($id: Int!, $value: ProjectParticipantSlotInput!) {
    updateProjectParticipantSlot (id: $id, value: $value) {
      id
      name
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
