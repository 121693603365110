<template>
  <v-card
    outlined
  >
    <v-card-text>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-row dense>
          <v-col>
            <v-alert
              type="error"
              dark
            >
              Please don't forget to add <strong>TAGS</strong> and set up <strong>PARTICIPANT SLOTS</strong> by using tab menu above!
            </v-alert>
          </v-col>
        </v-row>
        <v-divider class="mt-0 mb-6" />
        <v-row dense>
          <v-col>
            <v-alert
              v-if="projectClone.is_draft"
              color="primary"
              dark
            >
              Please disable <strong>DRAFT</strong> toggle so that others can see and join your project!
            </v-alert>
            <v-switch
              v-model="projectClone.is_draft"
              label="Draft (hidden from other users)"
              class="mt-0"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-0 mb-6" />
        <v-row dense>
          <v-col>
            <v-text-field
              v-model.trim="projectClone.name"
              :rules="[v => !!v || 'This field is required']"
              label="Project Name"
              clearable
              outlined
              dense
            />
            <v-textarea
              v-model="projectClone.description"
              :rules="[v => !!v || 'This field is required']"
              label="Short Project Description (Shown in Card, Plain Text Only)"
              :rows="7"
              auto-grow
              outlined
              dense
            />
          </v-col>
          <v-col>
            <file-upload-field
              v-model.trim="projectClone.cover_image_url"
              label="Cover Image URL"
              :rules="[
                value => !!value || 'Required',
                value => !!(value || '').startsWith('http://') || !!(value || '').startsWith('https://') || 'Must be an URL',
                value => /\.(png|jpe?g|gif)$/i.test(value || '') || 'Must end in .png, .jpg, .jpeg, .gif'
              ]"
              :event-id="event.id"
              :project-id="project.id"
              @success="value => projectClone.cover_image_url = value"
            />
            <v-text-field
              v-if="event.show_document_field"
              v-model.trim="projectClone.document"
              :rules="[v => (!v || (v || '').startsWith('https://docs.google.com')) || 'This field must include URL that leads to Google Docs']"
              label="Document URL"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-if="event.show_github_repository_field"
              v-model.trim="projectClone.github_repository"
              :rules="[v => (!v || (v || '').startsWith('https://github.cds.internal.unity3d.com') || (v || '').startsWith('https://github.com') || (v || '').startsWith('https://cloud.unity.com')) || 'This field must include URL that leads to GitHub or Unity Cloud']"
              label="Repository URL"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-if="event.show_slack_channel_field"
              v-model.trim="projectClone.slack_channel"
              label="Slack Channel Name (without #)"
              clearable
              outlined
              dense
            />
            <v-text-field
              v-if="event.show_video_field"
              v-model.trim="projectClone.video"
              label="Video URL"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <p>Full description (Shown in Project View)</p>
            <mavon-editor
              ref="editor-0"
              v-model="projectClone.markdown_description"
              :toolbars="require('@/mavonToolbars').default"
              :box-shadow="false"
              language="en"
              @imgAdd="(pos, $file) => $imgAdd(pos, $file, 'editor-0')"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="success"
        :loading="loading.update"
        :disabled="loading.update || !isDirty || !formValid"
        depressed
        @click="updateMutation"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileUploadField from './FileUploadField'
export default {
  components: { FileUploadField },
  name: 'ManageProjectGeneralData',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      projectClone: null,
      loading: {
        update: false
      },
      allowedKeys: [
        'name',
        'description',
        'markdown_description',
        'cover_image_url',
        'document',
        'github_repository',
        'slack_channel',
        'video',
        'is_draft'
      ],
      formValid: false
    }
  },
  computed: {
    onlyDirty () {
      return this._.pick(this._.deepDiff(this.projectClone, this.project), this.allowedKeys)
    },
    isDirty () {
      return this._.keys(this.onlyDirty).length
    }
  },
  methods: {
    validateForm () {
      this.$nextTick(function () {
        if (this.$refs.form) this.$refs.form.validate()
      })
    },
    cloneItem () {
      this.projectClone = this._.cloneDeep(this.project)
    },
    async $imgAdd (pos, $file, refName) {
      try {
        const formData = new FormData()
        formData.append('event', this.event.id)
        formData.append('project', this.project.id)
        formData.append('image', $file)
        const response = await this.$api.post(
          '/upload-image',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        if (!response.data.ok) {
          throw new Error(response.data.output)
        } else {
          this.$refs[refName].$img2Url(pos, response.data.output.viewImage)
        }
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'Mavon Error',
          text: error.message,
          duration: 10000
        })
      }
    },
    async updateMutation () {
      this.loading.update = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateProject').default,
          variables: {
            id: this.project.id,
            value: this.onlyDirty
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated project'
        })
        this.cloneItem()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    }
  },
  created () {
    this.cloneItem()
    this.validateForm()
  }
}
</script>
