import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  subscription projectLikesUpdated ($project_id: Int!) {
    projectLikesUpdated (project_id: $project_id) {
      id
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
