import gql from 'graphql-tag'
import ProjectAllFields from './fragments/ProjectAllFields'

export default gql`
  subscription projectUpdated ($slug: String!, $user: String!) {
    projectUpdated (slug: $slug, user: $user) {
      ...ProjectAllFields
    }
  }
  ${ProjectAllFields}
`
