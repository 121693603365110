import gql from 'graphql-tag'
import ProjectAllFields from './fragments/ProjectAllFields'

export default gql`
  mutation updateProject ($id: Int!, $value: ProjectInput!) {
    updateProject (id: $id, value: $value) {
      ...ProjectAllFields
    }
  }
  ${ProjectAllFields}
`
