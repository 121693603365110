import gql from 'graphql-tag'
import UserAllFields from './fragments/UserAllFields'

export default gql`
  mutation createProjectParticipantSlot ($project_id: Int!, $name: String!) {
    createProjectParticipantSlot (project_id: $project_id, name: $name) {
      id
      name
      user {
        ...UserAllFields
      }
    }
  }
  ${UserAllFields}
`
