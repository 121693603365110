<template>
  <div>
    <div>
      <v-text-field
        :value="value"
        clearable
        outlined
        dense
        v-bind="$attrs"
        @input="value => $emit('input', value)"
      >
        <template #append-outer>
          <file-upload
            ref="upload"
            v-model="files"
            v-bind="options"
            @input-file="inputFile"
            @input-filter="inputFilter"
          >
            <v-icon>
              mdi-upload
            </v-icon>
          </file-upload>
        </template>
      </v-text-field>
    </div>
    <v-card
      v-for="file in files"
      :key="file.id"
      class="mb-4"
      outlined
    >
      <v-progress-linear
        :color="fileStatusColor(file)"
        :value="file.progress"
      />
      <div class="d-flex justify-space-between pa-4">
        <div class="d-flex flex-column justify-space-between flex-grow-1 mr-4">
          <div>
            <div v-line-clamp="3">
              {{ _.get(file, ['response', 'output', 'viewImage'], file.name) }}
            </div>
            <div>
               ({{ prettyBytes(file.size) }})
            </div>
          </div>
          <v-alert
            class="mt-2 mb-0"
            :type="fileStatusColor(file)"
            dense
          >
            <div v-if="file.active">
              Uploading
            </div>
            <div v-else-if="file.error">
              {{ file.error }}
            </div>
            <div v-else-if="!file.response.ok">
              <div class="font-weight-bold">
                {{ file.response.status }}
              </div>
              <div>
                {{ file.response.output }}
              </div>
            </div>
            <div v-else-if="file.response.ok">
              {{ file.response.status }}
            </div>
          </v-alert>
        </div>
        <v-avatar
          v-if="file.blob"
          class="flex-grow-0"
          size="125"
          tile
        >
          <v-img
            min-width="128"
            :src="file.blob"
            aspect-ratio="1"
            contain
          />
        </v-avatar>
      </div>
    </v-card>
  </div>
</template>

<script>
import prettyBytes from 'pretty-bytes'

export default {
  name: 'FileUploadField',
  props: {
    value: {
      type: String,
      default: null
    },
    eventId: {
      type: Number,
      default: null
    },
    projectId: {
      type: Number,
      default: null
    }
  },
  data: function () {
    return {
      files: [],
      options: {
        name: 'image',
        postAction: `${this.$api.defaults.baseURL}/upload-image`,
        extensions: /\.(png|jpe?g|gif)$/i,
        accept: 'image/png,image/jpeg,image/gif',
        data: {
          event: this.eventId,
          project: this.projectId
        }
      }
    }
  },
  methods: {
    fileStatusColor (file) {
      if (file.active) return 'info'
      else if (file.success && file.response.ok) return 'success'
      else if (file.error || !file.response.ok) return 'error'
      else return 'warning'
    },
    prettyBytes,
    async inputFile (newFile, oldFile) {
      if (newFile && !oldFile) await this.onFileAdd(newFile, oldFile)
      if (newFile && oldFile) {
        // Update file
        // Start upload
        if (newFile.active !== oldFile.active) {
          await this.onFileUpload(newFile, oldFile)
          const sizeMax = 100 * 1024
          if (newFile.size <= 0 && newFile.size > sizeMax) {
            newFile = this.$refs.upload.update(newFile, { error: `Incorrect filesize ${prettyBytes(newFile.size)} must be <= ${prettyBytes(sizeMax)}` })
          }
        }
        // Upload progress
        if (newFile.progress !== oldFile.progress) await this.onFileProgress(newFile, oldFile)
        // Upload error
        if (newFile.error !== oldFile.error) await this.onFileError(newFile, oldFile)
        // Uploaded successfully
        if (newFile.success !== oldFile.success) await this.onFileSuccess(newFile, oldFile)
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) this.$refs.upload.active = true
      }
    },
    async inputFilter (newFile, oldFile, prevent) {
      if (!/\.(png|jpe?g|gif)$/i.test(newFile.name)) return prevent()
      newFile.blob = URL.createObjectURL(newFile.file)
    },
    async onFileAdd (newFile, oldFile) {},
    async onFileUpload (newFile, oldFile) {
      newFile.headers = {
        Authorization: `Bearer ${await this.$auth.getAccessToken()}`
      }
    },
    async onFileProgress (newFile, oldFile) {
      console.log('PROGRESS', newFile.progress)
    },
    async onFileError (newFile, oldFile) {
      console.log('ERROR', newFile.error)
    },
    async onFileSuccess (newFile, oldFile) {
      if (!newFile.response.ok) {
        newFile = this.$refs.upload.update(newFile, { error: newFile.response.output })
      } else {
        console.log('SUCCESS', newFile.success)
        this.$emit('success', newFile.response.output.viewImage)
      }
    }
  }
}
</script>
