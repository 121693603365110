<template>
  <v-container
    fluid
  >
    <v-alert
      v-if="cluster === 'test'"
      type="error"
    >
      <h3 class="h3">
        THIS IS THE TEST ENVIRONMENT, PLEASE USE
        <a
          class="h3 white--text"
          :href="`https://jam-board.ds.unity3d.com/event/${this.$route.params.eventSlug}`"
        >
          {{ `https://jam-board.ds.unity3d.com/event/${this.$route.params.eventSlug}` }}
        </a>
      </h3>
    </v-alert>
    <v-overlay
      v-if="$apollo.queries.event.loading || $apollo.queries.projects.loading"
      color="white"
      opacity="0.5"
      z-index="10"
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>

    <v-row
      v-if="event && (event.description || event.milestones.length)"
      class="pa-4"
      dense
    >
      <v-col :cols="12">
        <h1>
          Event Description
          <v-btn
            small
            icon
            text
            @click="SET_SHOW_EVENT_DESCRIPTION(!showEventDescription)"
          >
            <v-icon>
              {{ showEventDescription ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </h1>
      </v-col>
      <v-expand-transition>
        <v-sheet
          v-show="showEventDescription"
          width="100%"
        >
          <v-row dense>
            <v-col
              v-if="event.description"
              :cols="12"
              :lg="true"
            >
              <v-card
                class="ma-0"
                :style="{ 'min-height': '100%' }"
                outlined
              >
                <v-card-text>
                  <vue-markdown
                    class="markdown-body"
                    :source="event.description"
                    :anchor-attributes="{ target: '_blank' }"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="event.milestones.length"
              :cols="12"
              :lg="true"
            >
              <v-card
                class="px-4 ma-0"
                :style="{ 'min-height': '100%' }"
                outlined
              >
                <v-timeline>
                  <v-timeline-item
                    v-for="item in event.milestones"
                    :key="item.id"
                    :color="$moment(item.date_text).isBefore($moment(), 'day') || $moment(item.date_text).isSame($moment(), 'day') ? 'green' : item.color"
                    :icon="$moment(item.date_text).isBefore($moment(), 'day') || $moment(item.date_text).isSame($moment(), 'day') ? 'mdi-check' : undefined"
                    small
                    dark
                  >
                    <template v-slot:opposite>
                      <span class="font-weight-bold info--text">
                        <div class="headline">
                          {{ item.date_text }}
                        </div>
                        <div>
                          {{ item.date_text | moment("dddd") }}
                        </div>
                      </span>
                    </template>
                    <div class="py-4">
                      <h2 :class="`headline font-weight-light mb-4 ${item.color}--text`">
                        {{ item.title }}
                      </h2>
                      <div>
                        {{ item.description }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-expand-transition>
    </v-row>

    <v-row
      v-if="event && event.is_enabled"
      class="pa-4"
      dense
    >
      <v-col
        :cols="12"
        :lg="4"
        :sm="6"
      >
        <div class="d-flex align-center justify-start">
          <v-select
            v-model="orderBy"
            :items="orderTypes"
            item-text="label"
            class="mr-2"
            label="Order By"
            :menu-props="{ offsetY: true }"
            :disabled="!projects.length"
            return-object
            hide-details
            outlined
            dense
          />
          <v-btn
            :disabled="!projects.length"
            small
            icon
            text
            @click="orderDirection === 'desc' ? orderDirection = 'asc' : orderDirection = 'desc'"
          >
            <v-icon>
              {{ orderDirection === 'desc' ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col
        :cols="12"
        :lg="4"
        :sm="6"
      >
        <v-select
          v-model="filterTags"
          :items="orderedTags"
          item-text="display_name"
          label="Filter by Tag"
          :menu-props="{ offsetY: true }"
          :disabled="!projects.length"
          return-object
          clearable
          multiple
          hide-details
          outlined
          dense
        >
          <template v-slot:selection="{ item }">
            <v-chip
              label
              close
              @click:close="filterTags = filterTags.filter(tag => tag.id !== item.id)"
            >
              {{ item.display_name }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="d-flex align-start justify-end"
        :cols="12"
        :lg="4"
        :sm="12"
      >
        <ApolloMutation
          :mutation="require('@/gql/createProject').default"
          :variables="{ event_id: event.id, name: 'New Project' }"
          :update="updateCacheOnCreateProject"
          @error="error => $notify({ group: 'dashboard', type: 'error', title: error.message })"
          @done="$notify({ group: 'dashboard', type: 'success', title: 'Successfully created project' })"
        >
          <template v-slot="{ mutate, loading }">
            <v-btn
              class="mr-0"
              :loading="loading"
              :disabled="loading || !event.is_active"
              color="primary"
              depressed
              @click="mutate()"
            >
              New Project
            </v-btn>
          </template>
        </ApolloMutation>
      </v-col>
    </v-row>

    <v-row
      v-if="event && !projects.length"
      class="px-4 py-4"
      dense
    >
      <v-col
        v-if="event.is_enabled"
        :cols="12"
      >
        <v-alert
          type="info"
          prominent
        >
          <div class="text-h6">
            There are no <strong>Projects</strong> in this <strong>Event</strong> yet, be the first one!
          </div>
        </v-alert>
      </v-col>
      <v-col
        v-if="!event.is_enabled"
        :cols="12"
      >
        <v-alert
          type="error"
          prominent
        >
          <div class="text-h6">
            This <strong>Event</strong> is no longer active
          </div>
          If you think this is a mistake, contact the owner <strong>{{ event.owner.name }}</strong>
        </v-alert>
      </v-col>
    </v-row>

    <template v-if="event && !$apollo.queries.event.loading && event.is_enabled && projects.length">
      <v-row
        class="px-4 pb-4"
        dense
      >
        <v-col>
          <activity-bar
            :event="event"
          />
        </v-col>
      </v-row>

      <!-- OWNED PROJECTS -->
      <v-row
        v-if="ownedProjects.length"
        class="px-4 pb-4"
        dense
      >
        <v-col :cols="12">
          <h1>
            Owned Projects
            <v-chip
              class="mx-2"
              small
            >
              {{ ownedProjects.length }}
            </v-chip>
            <v-btn
              small
              icon
              text
              @click="SET_SHOW_OWNED_PROJECTS(!showOwnedProjects)"
            >
              <v-icon>
                {{ showOwnedProjects ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
        <v-expand-transition>
          <transition-group
            class="d-flex flex-wrap"
            :style="{ 'width': '100%' }"
            name="flip-grid"
          >
            <v-col
              v-for="project in ownedProjects"
              v-show="showOwnedProjects"
              :key="project.id"
              :cols="12"
              :xl="3"
              :lg="6"
              :md="6"
              :sm="12"
            >
              <project-card
                :event="event"
                :project="project"
              />
            </v-col>
          </transition-group>
        </v-expand-transition>
        <v-expand-transition>
          <v-col
            v-show="!ownedProjects.length && showOwnedProjects"
            :cols="12"
          >
            <v-alert
              border="left"
              type="info"
            >
              You have no projects yet
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>

      <!-- JOINED PROJECTS -->
      <v-row
        v-if="joinedProjects.length"
        class="px-4 pb-4"
        dense
      >
        <v-col :cols="12">
          <h1>
            Joined Projects
            <v-chip
              class="mx-2"
              small
            >
              {{ joinedProjects.length }}
            </v-chip>
            <v-btn
              small
              icon
              text
              @click="SET_SHOW_JOINED_PROJECTS(!showJoinedProjects)"
            >
              <v-icon>
                {{ showJoinedProjects ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
        <v-expand-transition>
          <transition-group
            class="d-flex flex-wrap"
            :style="{ 'width': '100%' }"
            name="flip-grid"
          >
            <v-col
              v-for="project in joinedProjects"
              v-show="showJoinedProjects"
              :key="project.id"
              :cols="12"
              :xl="3"
              :lg="6"
              :md="6"
              :sm="12"
            >
              <project-card
                :event="event"
                :project="project"
              />
            </v-col>
          </transition-group>
        </v-expand-transition>
        <v-expand-transition>
          <v-col
            v-show="!joinedProjects.length && showJoinedProjects"
            :cols="12"
          >
            <v-alert
              border="left"
              type="info"
            >
              You have no joined projects yet
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>

      <!-- LIKED PROJECTS -->
      <v-row
        v-if="likedProjects.length"
        class="px-4 pb-4"
        dense
      >
        <v-col :cols="12">
          <h1>
            Liked Projects
            <v-chip
              class="mx-2"
              small
            >
              {{ likedProjects.length }}
            </v-chip>
            <v-btn
              small
              icon
              text
              @click="SET_SHOW_LIKED_PROJECTS(!showLikedProjects)"
            >
              <v-icon>
                {{ showLikedProjects ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
        <v-expand-transition>
          <transition-group
            class="d-flex flex-wrap"
            :style="{ 'width': '100%' }"
            name="flip-grid"
          >
            <v-col
              v-for="project in likedProjects"
              v-show="showLikedProjects"
              :key="project.id"
              :cols="12"
              :xl="3"
              :lg="6"
              :md="6"
              :sm="12"
            >
              <project-card
                :event="event"
                :project="project"
              />
            </v-col>
          </transition-group>
        </v-expand-transition>
        <v-expand-transition>
          <v-col
            v-show="!likedProjects.length && showLikedProjects"
            :cols="12"
          >
            <v-alert
              border="left"
              type="info"
            >
              You have no liked projects yet
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>

      <!-- OTHER PROJECTS -->
      <v-row
        class="px-4 pb-4"
        dense
      >
        <v-col :cols="12">
          <h1>
            Projects
            <v-chip
              class="mx-2"
              small
            >
              {{ otherProjects.length }}
            </v-chip>
            <v-btn
              small
              icon
              text
              @click="SET_SHOW_OTHER_PROJECTS(!showOtherProjects)"
            >
              <v-icon>
                {{ showOtherProjects ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
        <v-expand-transition>
          <transition-group
            class="d-flex flex-wrap"
            :style="{ 'width': '100%' }"
            name="flip-grid"
          >
            <v-col
              v-for="project in otherProjects"
              v-show="showOtherProjects"
              :key="project.id"
              :cols="12"
              :xl="3"
              :lg="6"
              :md="6"
              :sm="12"
            >
              <project-card
                :event="event"
                :project="project"
              />
            </v-col>
          </transition-group>
        </v-expand-transition>
        <v-expand-transition>
          <v-col
            v-show="!otherProjects.length && showOtherProjects"
            :cols="12"
          >
            <v-alert
              border="left"
              type="info"
            >
              There are no other users' projects yet
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>

      <v-row
        class="px-4 pb-8"
        dense
      >
        <v-col :cols="12">
          <h1>
            Dropped Projects
            <v-chip
              class="mx-2"
              small
            >
              {{ droppedProjects.length }}
            </v-chip>
            <v-btn
              small
              icon
              text
              @click="SET_SHOW_DROPPED_PROJECTS(!showDroppedProjects)"
            >
              <v-icon>
                {{ showDroppedProjects ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </h1>
        </v-col>
        <v-expand-transition>
          <transition-group
            class="d-flex flex-wrap"
            :style="{ 'width': '100%' }"
            name="flip-grid"
          >
            <v-col
              v-for="project in droppedProjects"
              v-show="showDroppedProjects"
              :key="project.id"
              :cols="12"
              :xl="3"
              :lg="6"
              :md="6"
              :sm="12"
            >
              <project-card
                :event="event"
                :project="project"
              />
            </v-col>
          </transition-group>
        </v-expand-transition>
        <v-expand-transition>
          <v-col
            v-show="!droppedProjects.length && showDroppedProjects"
            :cols="12"
          >
            <v-alert
              border="left"
              type="info"
            >
              There are no dropped projects yet
            </v-alert>
          </v-col>
        </v-expand-transition>
      </v-row>
    </template>

    <v-row
      v-if="event && event.is_enabled"
      class="px-4"
      dense
    >
      <v-col :cols="12">
        <v-expansion-panels
          v-model="expandedPanels"
          multiple
          flat
        >
          <v-expansion-panel>
            <v-card
              class="ma-0 mb-2"
              outlined
            >
              <v-expansion-panel-header>Stats</v-expansion-panel-header>
              <v-expansion-panel-content class="pt-8">
                <v-row dense>
                  <v-col
                    :cols="12"
                    :lg="3"
                    :md="6"
                    :sm="12"
                  >
                    <base-material-stats-card
                      color="primary"
                      icon="mdi-card-bulleted"
                      title="Total Projects"
                      :value="computedStats.active_projects"
                    />
                  </v-col>
                  <v-col
                    :cols="12"
                    :lg="3"
                    :md="6"
                    :sm="12"
                  >
                    <base-material-stats-card
                      color="primary"
                      icon="mdi-account"
                      title="Total Participants"
                      :value="computedStats.total_participants"
                    />
                  </v-col>
                  <v-col
                    :cols="12"
                    :lg="3"
                    :md="6"
                    :sm="12"
                  >
                    <base-material-stats-card
                      color="primary"
                      icon="mdi-tag"
                      title="Active Tags"
                      :value="computedStats.active_tags"
                    />
                  </v-col>
                  <v-col
                    :cols="12"
                    :lg="3"
                    :md="6"
                    :sm="12"
                  >
                    <base-material-stats-card
                      color="primary"
                      icon="mdi-thumb-up"
                      title="Active Likes"
                      :value="computedStats.active_likes"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
          <v-expansion-panel :disabled="!computedTagsForCloud.length">
            <v-card
              class="ma-0"
              outlined
            >
              <v-expansion-panel-header>Tag Cloud</v-expansion-panel-header>
              <v-expansion-panel-content class="pa-4">
                <vue-word-cloud
                  :style="{ height: '480px' }"
                  :words="computedTagsForCloud"
                  :spacing="0.15"
                  :color="([word, weight]) => weight === 0 ? 'red' : $randomColor({ luminosity: 'bright', hue: 'green', seed: word, format: 'rgba', alpha: (1 / weight) >= 0.6 ? (1 / weight) : 0.6 })"
                  font-family="Roboto"
                >
                  <template v-slot="{ text, weight, word }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div
                          style="cursor: pointer;"
                          @click="filterTags = [_.find(event.tags, { display_name: word[0] })]"
                          v-on="on"
                        >
                          {{ text }}
                        </div>
                      </template>
                      <span>The tag "{{ text }}" was used in {{ weight }} {{ 'project' | pluralize(weight) }} during this event</span>
                    </v-tooltip>
                  </template>
                </vue-word-cloud>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col :cols="12">
        <v-alert
          type="error"
          prominent
        >
          <div class="text-h6">
            Could not find any events with slug "<strong>{{ $route.params.eventSlug }}</strong>"
          </div>
          Make sure it exists or that the owner has given you access if the event is private
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ActivityBar from './components/core/ActivityBar'
import ProjectCard from './components/core/ProjectCard'

export default {
  name: 'DashboardEvent',
  components: { ActivityBar, ProjectCard },
  data: function () {
    const orderTypes = [
      {
        label: 'Number of Participants',
        path: (project) => project.slots.filter(slot => slot.user).length
      },
      {
        label: 'Number of Likes',
        path: (project) => project.likes.length
      },
      {
        label: 'Date Created',
        path: (project) => project.created
      },
      {
        label: 'Date Updated',
        path: (project) => project.updated
      }
    ]
    return {
      cluster: process.env.CLUSTER,
      event: null,
      projects: [],
      filterTags: [],
      orderTypes,
      orderBy: orderTypes[0],
      orderDirection: 'asc',
      openedProject: false
    }
  },
  computed: {
    ...mapState('main', ['expandedPanelsEvent', 'showEventDescription', 'showOwnedProjects', 'showJoinedProjects', 'showLikedProjects', 'showOtherProjects', 'showDroppedProjects']),
    ...mapState('user', ['claims']),
    currentUserIsEventOwner () {
      return this.event.owner.id === this.claims.sub
    },
    currentUserIsEventModerator () {
      return !!this._.find(this.event.moderators, moderator => moderator.user.id === this.claims.sub)
    },
    orderedTags () {
      if (!this.event) return []
      return this._(this.event.tags)
        .orderBy(['display_name'], ['asc'])
        .value()
    },
    filteredProjects () {
      const filters = [
        project => (!this.filterTags || !this.filterTags.length) ||
            (project.tags && this._.some(project.tags.filter(projectTag => projectTag.is_enabled), projectTag => this._.find(this.filterTags, filterTag => filterTag.id === projectTag.tag.id)))
      ]
      return this._.filter(this.projects, project => this._.some(filters, filter => filter(project)))
    },
    ownedProjects () {
      return this.lodashOrderByFunctionForFilter(
        this._(this.filteredProjects)
          .filter(project => project.is_enabled)
          .filter(project => project.owner.id === this.claims.sub)
          .value()
      )
    },
    joinedProjects () {
      return this.lodashOrderByFunctionForFilter(
        this._(this.filteredProjects)
          .filter(project => project.is_enabled)
          .filter(project => project.owner.id !== this.claims.sub)
          .filter(project => project.slots.filter(slot => slot.user).map(slot => slot.user.id).includes(this.claims.sub))
          .value()
      )
    },
    likedProjects () {
      return this.lodashOrderByFunctionForFilter(
        this._(this.filteredProjects)
          .filter(project => project.is_enabled)
          .filter(project => project.owner.id !== this.claims.sub)
          .filter(project => !project.slots.filter(slot => slot.user).map(slot => slot.user.id).includes(this.claims.sub))
          .filter(project => project.likes.filter(like => like.user).map(slot => slot.user.id).includes(this.claims.sub))
          .value()
      )
    },
    otherProjects () {
      return this.lodashOrderByFunctionForFilter(
        this._(this.filteredProjects)
          .filter(project => project.is_enabled)
          .filter(project => project.owner.id !== this.claims.sub)
          .filter(project => !project.slots.filter(slot => slot.user).map(slot => slot.user.id).includes(this.claims.sub))
          .filter(project => !project.likes.filter(like => like.user).map(slot => slot.user.id).includes(this.claims.sub))
          .value()
      )
    },
    droppedProjects () {
      return this.lodashOrderByFunctionForFilter(
        this._(this.filteredProjects)
          .filter(project => !project.is_enabled)
          .value()
      )
    },
    computedTagsForCloud () {
      if (!this.event || !this.projects) return []
      const eventTags = this.event.tags
      const projectTags = this._(this.projects).map('tags').flatten().map('tag').value()
      const allTags = this._([...eventTags, ...projectTags]).countBy('display_name').mapValues(value => --value).toPairs().value()
      return allTags
    },
    computedStats () {
      const activeProjects = this.projects.filter(project => project.is_enabled)
      return {
        active_projects: activeProjects.length,
        total_participants: this._(activeProjects).map('slots').flatten().filter('user').uniqBy('user.id').value().length,
        active_tags: this._(activeProjects).map('tags').flatten().uniqBy('tag.id').value().length,
        active_likes: this._(activeProjects).map('likes').flatten().value().length
      }
    },
    expandedPanels: {
      get () {
        return this.expandedPanelsEvent
      },
      set (value) {
        this.SET_EXPANDED_PANELS_EVENT(value)
      }
    }
  },
  watch: {
    event (newValue) {
      console.log('EVENT HAS CHANGED', newValue)
      if (newValue) this.SET_TITLE(newValue.name)
    },
    'ownedProjects.length' () {
      this.SET_SHOW_OWNED_PROJECTS(true)
    },
    'joinedProjects.length' () {
      this.SET_SHOW_JOINED_PROJECTS(true)
    },
    'likedProjects.length' () {
      this.SET_SHOW_LIKED_PROJECTS(true)
    }
  },
  methods: {
    ...mapActions('main', ['SET_TITLE', 'SET_EXPANDED_PANELS_EVENT', 'SET_SHOW_EVENT_DESCRIPTION', 'SET_SHOW_OWNED_PROJECTS', 'SET_SHOW_JOINED_PROJECTS', 'SET_SHOW_LIKED_PROJECTS', 'SET_SHOW_OTHER_PROJECTS', 'SET_SHOW_DROPPED_PROJECTS']),
    updateCacheOnCreateProject (store, { data: { createProject } }) {
      console.log(`Updating cache, will add project ${createProject.id}`)
      // Read from cache
      const data = store.readQuery({
        query: require('@/gql/getProjects').default,
        variables: { slug: this.$route.params.eventSlug }
      })
      // Write it back
      store.writeQuery({
        query: require('@/gql/getProjects').default,
        variables: { slug: this.$route.params.eventSlug },
        data: {
          projects: this._.unionBy(data.projects, [createProject], 'id')
        }
      })
      console.log('Done updating cache!')
    },
    lodashOrderByFunctionForFilter (input) {
      return this._(input)
        .orderBy(
          [
            project => project.is_draft,
            project => !!this._.find(project.slots, item => item.user && item.user.id === this.claims.sub),
            project => !!this._.find(project.likes, item => item.user.id === this.claims.sub),
            this.orderBy.path
          ],
          [
            'desc',
            'desc',
            'desc',
            this.orderDirection
          ]
        )
        .value()
    }
  },
  apollo: {
    event: {
      query: require('@/gql/getEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      }
    },
    projects: {
      query: require('@/gql/getProjects').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      },
      subscribeToMore: [
        {
          document: require('@/gql/subscribeProjectCreated').default,
          variables () {
            return {
              slug: this.$route.params.eventSlug,
              user: this.claims.sub
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { projectCreated } } }) {
            console.log(`Received 'projectCreated' event, will attempt adding project ${projectCreated.id}`)
            if (!projectCreated) {
              console.log('Received \'projectCreated\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              projects: this._.unionBy(previousResult.projects, [projectCreated], 'id')
            }
          }
        },
        {
          document: require('@/gql/subscribeProjectUpdated').default,
          variables () {
            return {
              slug: this.$route.params.eventSlug,
              user: this.claims.sub
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { projectUpdated } } }) {
            console.log(`Received 'projectUpdated' event, will attempt updating project ${projectUpdated.id}`)
            if (!projectUpdated) {
              console.log('Received \'projectUpdated\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              projects: this._.unionBy(previousResult.projects, [projectUpdated], 'id')
            }
          }
        },
        {
          document: require('@/gql/subscribeProjectDeleted').default,
          variables () {
            return {
              slug: this.$route.params.eventSlug,
              user: this.claims.sub
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { projectDeleted } } }) {
            console.log(`Received 'projectDeleted' event, will attempt removing project ${projectDeleted.id}`)
            if (!projectDeleted) {
              console.log('Received \'projectDeleted\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              projects: previousResult.projects.filter(project => project.id !== projectDeleted.id)
            }
          }
        }
      ]
    }
  }
}
</script>
