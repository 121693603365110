<template>
  <v-row dense>
    <v-col class="d-flex align-center flex-grow-0">
      <v-avatar
        v-if="item.user"
        :size="32"
      >
        <img
          :src="item.user.slack_picture"
          :alt="item.user.name"
        >
      </v-avatar>
      <v-avatar
        v-else
        :size="32"
        color="grey"
      >
        <v-icon
          dark
        >
          mdi-account
        </v-icon>
      </v-avatar>
    </v-col>
    <v-col>
      <v-text-field
        v-model="itemClone.name"
        :loading="loading.update"
        :disabled="loading.update"
        label="Role"
        hide-details
        clearable
        outlined
        dense
        @input="$emit('input')"
      />
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="info"
        :loading="loading.update"
        :disabled="loading.update || !isDirty"
        depressed
        x-small
        fab
        @click="updateMutation"
      >
        <v-icon>
          mdi-content-save
        </v-icon>
      </v-btn>
    </v-col>
    <v-col class="d-flex align-center flex-grow-0">
      <v-btn
        class="mx-0"
        color="error"
        :loading="loading.delete"
        :disabled="loading.delete || project.slots.length <= event.min_participants"
        depressed
        x-small
        fab
        @click="deleteMutation"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ManageProjectParticipantSlots',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    itemClone: null,
    loading: {
      update: false,
      delete: false
    },
    allowedKeys: [
      'name'
    ]
  }),
  computed: {
    onlyDirty () {
      return this._.pick(this._.deepDiff(this.itemClone, this.item), this.allowedKeys)
    },
    isDirty () {
      return this._.keys(this.onlyDirty).length
    }
  },
  created () {
    this.cloneItem()
  },
  methods: {
    cloneItem () {
      this.itemClone = this._.cloneDeep(this.item)
      this.$emit('input')
    },
    async updateMutation () {
      this.loading.update = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateProjectParticipantSlot').default,
          variables: {
            id: this.item.id,
            value: this.onlyDirty,
            update: (store, { data: { updateProjectParticipantSlot } }) => {
              const data = store.readQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug } })
              const currentlyCachedProject = this._.find(data.projects, { id: this.project.id })
              currentlyCachedProject.slots = this._.unionBy(currentlyCachedProject.slots, [updateProjectParticipantSlot], 'id')
              store.writeQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug }, data })
            }
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated participant slot'
        })
        this.cloneItem()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    },
    async deleteMutation () {
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteProjectParticipantSlot').default,
          variables: { id: this.item.id },
          update: (store, { data: { deleteProjectParticipantSlot } }) => {
            const data = store.readQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug } })
            const currentlyCachedProject = this._.find(data.projects, { id: this.project.id })
            currentlyCachedProject.slots = currentlyCachedProject.slots.filter(slot => slot.id !== deleteProjectParticipantSlot.id)
            store.writeQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug }, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteProjectParticipantSlot: {
              __typename: 'ProjectParticipantSlot',
              id: this.item.id
            }
          }
        })
        this.$emit('update')
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.delete = false
    }
  }
}
</script>
