<template>
  <v-card
    outlined
  >
    <v-card-text>
      <!--<v-chip
        v-for="tag in event.tags"
        :key="tag.id"
        class="mr-2 mb-2"
        active-class="primary--text"
        :input-value="itemClone.map(tag => tag.id).includes(tag.id)"
        @click="toggleProjectTag(tag)"
      >
        {{ tag.display_name }}
      </v-chip>-->
      <v-autocomplete
        v-model="itemClone"
        class="mb-4"
        item-value="id"
        item-text="display_name"
        :items="event.tags"
        :menu-props="{ offsetY: true }"
        label="Project Tags"
        auto-select-first
        return-object
        hide-details
        clearable
        outlined
        multiple
      >
        <template v-slot:item="{ item }">
          <div>
            {{ item.display_name }}
            <div class="text--disabled">
              {{ item.description }}
            </div>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <v-chip
            label
            close
            @click:close="itemClone = itemClone.filter(tag => tag.id !== item.id)"
          >
            {{ item.display_name }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-btn
        color="success"
        :loading="loading.update"
        :disabled="loading.update || isDirty"
        depressed
        @click="updateMutation"
      >
        Save
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ManageProjectTags',
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    itemClone: null,
    loading: {
      update: false
    }
  }),
  computed: {
    isDirty () {
      return this._.isEqual(this.itemClone, this.project.tags.map(tag => tag.tag))
    }
  },
  created () {
    this.cloneItem()
  },
  methods: {
    cloneItem () {
      this.itemClone = this._.cloneDeep(this.project.tags.map(tag => tag.tag))
    },
    async updateMutation () {
      this.loading.update = true
      try {
        const payload = {
          tags: this.itemClone.map(tag => tag.id)
        }
        await this.$apollo.mutate({
          mutation: require('@/gql/updateProject').default,
          variables: {
            id: this.project.id,
            value: payload
          }
        })
        this.$notify({
          group: 'dashboard',
          type: 'success',
          title: 'Successfully updated tags'
        })
        this.cloneItem()
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.update = false
    },
    toggleProjectTag (tag) {
      console.log('tag', tag)
    }
  }
}
</script>
