<template>
  <v-row dense>
    <v-col
      class="d-flex align-center justify-space-between"
      :cols="12"
    >
      <h1>
        Activity
        <v-btn
          :loading="$apollo.queries.comments.loading"
          :disabled="$apollo.queries.comments.loading"
          small
          icon
          text
          @click="SET_SHOW_ACTIVITY_BAR(!showActivityBar)"
        >
          <v-icon>
            {{ showActivityBar ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </h1>
      <div class="d-flex align-center">
        <v-btn
          class="mr-2"
          color="primary"
          :to="`/report/${event.slug}/user/me`"
          depressed
          outlined
          x-small
        >
          My Activity
        </v-btn>
        <div v-show="showActivityBar && !$apollo.queries.comments.loading">
          <v-icon
            class="mr-2"
            @click="() => { if (--currentPage < 0) { currentPage = pages.length - 1 } else { currentPage-- } }"
          >
            mdi-chevron-left
          </v-icon>
          {{ currentPage + 1 }} / {{ pages.length }}
          <v-icon
            class="ml-2"
            @click="currentPage++"
          >
            mdi-chevron-right
          </v-icon>
        </div>
      </div>
    </v-col>
    <v-expand-transition>
      <v-sheet
        v-show="showActivityBar && !$apollo.queries.comments.loading"
        width="100%"
      >
        <v-carousel
          v-model="currentPage"
          height="auto"
          :show-arrows="false"
          hide-delimiters
        >
          <v-carousel-item
            v-for="(page, index) in pages"
            :key="index"
            light
          >
            <v-row
              class="fill-height px-1"
              dense
            >
              <v-col
                v-for="item in page"
                :key="item.id"
                :cols="12"
                :md="true"
              >
                <v-card
                  class="ma-0"
                  height="100%"
                  min-height="160"
                  :to="`/event/${event.slug}/${item.project.random_key}`"
                  outlined
                >
                  <v-card-text
                    class="d-flex align-start justify-space-between fill-height"
                  >
                    <slack-avatar
                      :user="item.user"
                    />
                    <div class="d-flex flex-grow-1 flex-column align-start justify-space-between fill-height pl-4">
                      <div :style="{ width: '100%' }">
                        <small v-line-clamp="1">
                          {{ item.project.name }}
                        </small>
                        <v-divider class="my-1" />
                        <div
                          v-line-clamp="4"
                          :style="{ 'white-space': 'pre-line' }"
                        >
                          {{ item.value }}
                        </div>
                      </div>
                      <small class="align-self-end text--disabled">
                        {{ item.updated * 1000 | moment('from', 'now') }}
                      </small>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
    </v-expand-transition>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SlackAvatar from './SlackAvatar'
export default {
  name: 'ActivityBar',
  components: { SlackAvatar },
  props: {
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    comments: [],
    currentPage: 0,
    limit: 50
  }),
  computed: {
    ...mapState('main', ['showActivityBar']),
    ...mapState('user', ['claims']),
    commentsOrdered () {
      return this._.orderBy(this.comments, ['created'], ['desc'])
    },
    pages () {
      return this._.chunk(this.commentsOrdered, 3)
    }
  },
  watch: {
  },
  mounted () {
    console.log(this.$options.name, 'mounted')
    this.refetch()
  },
  methods: {
    ...mapActions('main', ['SET_SHOW_ACTIVITY_BAR']),
    refetch () {
      console.log(this.$options.name, 'Re-fetching')
      this.$apollo.queries.comments.refetch()
    }
  },
  apollo: {
    comments: {
      query: require('@/gql/getCommentsByEvent').default,
      variables () {
        return {
          event_id: this.event.id,
          limit: this.limit
        }
      },
      subscribeToMore: [
        {
          document: require('@/gql/subscribeFeedCommentCreated').default,
          variables () {
            return {
              event_id: this.event.id,
              user: this.claims.sub
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { feedCommentCreated } } }) {
            console.log(`Received 'feedCommentCreated' event, will attempt adding project ${feedCommentCreated.id}`)
            if (!feedCommentCreated) {
              console.log('Received \'feedCommentCreated\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              comments: this._(previousResult.comments).unionBy([feedCommentCreated], 'id').orderBy(['created'], ['desc']).take(this.limit).value()
            }
          }
        },
        {
          document: require('@/gql/subscribeFeedCommentUpdated').default,
          variables () {
            return {
              event_id: this.event.id,
              user: this.claims.sub
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { feedCommentUpdated } } }) {
            console.log(`Received 'feedCommentUpdated' event, will attempt updating project ${feedCommentUpdated.id}`)
            if (!feedCommentUpdated) {
              console.log('Received \'feedCommentUpdated\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              comments: this._(previousResult.comments).unionBy([feedCommentUpdated], 'id').orderBy(['created'], ['desc']).take(this.limit).value()
            }
          }
        },
        {
          document: require('@/gql/subscribeFeedCommentDeleted').default,
          variables () {
            return {
              event_id: this.event.id,
              user: this.claims.sub
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { feedCommentDeleted } } }) {
            console.log(`Received 'feedCommentDeleted' event, will attempt removing project ${feedCommentDeleted.id}`)
            if (!feedCommentDeleted) {
              console.log('Received \'feedCommentDeleted\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              comments: this._(previousResult.comments).filter(comment => comment.id !== feedCommentDeleted.id).take(100).value()
            }
          }
        }
      ]
    }
  }
}
</script>
