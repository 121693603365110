<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="zoom transition-swing my-2"
        :class="{ 'text--disabled' : !project.is_enabled }"
        :elevation="hover ? 4 : 0"
        :color="project.is_draft ? 'grey lighten-4' : undefined"
        outlined
      >
        <v-img
          class="clickable"
          height="260px"
          position="top center"
          :style="{ 'background-color': colors.grey.lighten2 }"
          :src="project.cover_image_url || `${baseUrl}/blank_image.png`"
          :gradient="
            !project.is_draft ? `to bottom, rgba(255, 255, 255, 0.0) 35%, rgba(255, 255, 255, 0.6) 75%, rgba(255, 255, 255, 1.0) 95%` : `to bottom, rgba(245, 245, 245, 0.1) 35%, rgba(245, 245, 245, 0.6) 75%, rgba(245, 245, 245, 1.0) 95%`
          "
          @click="viewProject()"
        >
          <div class="d-flex flex-column justify-space-between fill-height">
            <div>
              <div class="pa-4">
                <v-chip
                  v-for="badge in _(project.comments).filter(comment => comment.category).groupBy('category.display_name').toPairs().map(pair => ({ field: pair[0], count: pair[1].length })).value()"
                  :key="badge.field"
                  class="mr-1 mb-2 px-1"
                  color="primary"
                  label
                  x-small
                >
                  {{ badge.count }}
                  {{ badge.field | pluralize(badge.count) }}
                </v-chip>
              </div>
            </div>
            <v-card-title class="d-flex flex-nowrap align-center justify-space-between py-4">
              <div class="d-flex flex-nowrap align-center justify-start flex-grow-1">
                <div class="d-flex flex-nowrap align-center justify-start mr-2">
                  <slack-avatar
                    class="mr-2"
                    :size="32"
                    :user="project.owner"
                    @click.prevent
                  />
                  <span
                    v-if="project.is_draft"
                    class="mr-2"
                  >
                    <v-tooltip
                      :open-delay="500"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="pulse"
                          color="primary"
                          :size="32"
                          v-on="on"
                        >
                          mdi-shield-edit
                        </v-icon>
                      </template>
                      <span>DRAFT (other users can't see your project)</span>
                    </v-tooltip>
                  </span>
                </div>
                <v-tooltip
                  :open-delay="500"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="flex-grow-1"
                      v-on="on"
                    >
                      <div v-line-clamp="1">
                        {{ project.name }}
                      </div>
                    </div>
                  </template>
                  <span>{{ project.name }}</span>
                </v-tooltip>
              </div>
              <v-menu
                v-if="currentUserIsOwner || currentUserIsEventOwner || currentUserIsEventModerator"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="loading.delete || (!event.is_active && !(currentUserIsEventOwner || currentUserIsEventModerator))"
                    :loading="loading.delete"
                    fab
                    text
                    x-small
                    v-on="on"
                    @click.prevent
                  >
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editProject()">
                    <v-list-item-title>
                      Edit Project
                    </v-list-item-title>
                  </v-list-item>
                  <ApolloMutation
                    :mutation="require('@/gql/updateProject').default"
                    :variables="{ id: project.id, value: { is_enabled: !project.is_enabled } }"
                    @loading="(isLoading) => { loading = isLoading }"
                    @error="error => $notify({ group: 'dashboard', type: 'error', title: error.message })"
                    @done="$notify({ group: 'dashboard', type: 'success', title: 'Successfully updated project' })"
                  >
                    <template v-slot="{ mutate }">
                      <v-list-item
                        v-if="!project.is_draft"
                        @click="mutate()"
                      >
                        <v-list-item-title>
                          {{ project.is_enabled ? 'Mark Project as Dropped' : 'Mark Project as Active' }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </ApolloMutation>
                  <v-list-item
                    v-if="currentUserIsOwner || currentUserIsEventOwner || currentUserIsEventModerator"
                    @click="deleteMutation"
                  >
                    <v-list-item-title class="red--text">
                      Delete Project
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
          </div>
        </v-img>

        <v-divider />

        <div class="pa-4 pb-0">
          <div
            v-line-clamp="4"
            :style="{ 'height': '96px', 'min-height': '96px', 'white-space': 'pre-line' }"
          >
            {{ project.description || 'No description' }}
          </div>
        </div>

        <v-sheet
          v-if="project.tags.length"
          color="transparent"
          class="pa-4"
          @click.prevent
        >
          <v-slide-group
            class="tag-slide-group d-flex align-center"
            show-arrows
          >
            <v-slide-item
              v-for="tag in project.tags"
              :key="tag.id"
            >
              <v-tooltip
                :disabled="!tag.tag.description"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :disabled="!project.is_enabled"
                    class="mr-1"
                    label
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ tag.tag.display_name }}
                  </v-chip>
                </template>
                <span>{{ tag.tag.description }}</span>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <div
          v-else
          class="d-flex primary--text pa-4"
        >
          <div
            class="pulse-small"
          >
            No tags, please add some!
          </div>
        </div>

        <v-divider />

        <div
          class="px-2 pt-1 pb-0"
          :style="{ 'min-height': '40px' }"
        >
          <project-card-participant-slots
            :event="event"
            :project="project"
            dense
          />
        </div>

        <v-divider />

        <v-card-actions class="d-flex align-center justify-space-between px-4 py-2">
          <div class="d-flex align-center justify-start">
            <v-btn
              class="mr-1"
              :disabled="loading.like || !project.is_enabled || !event.is_active"
              :loading="loading.like"
              x-small
              text
              fab
              @click="likeMutation"
            >
              <v-icon :color="currentUserLike ? 'primary' : 'grey'">
                mdi-thumb-up
              </v-icon>
            </v-btn>
          </div>
          <div>
            <v-tooltip
              :open-delay="500"
              :max-width="500"
              bottom
            >
              <template
                v-slot:activator="{ on }"
              >
                <span
                  class="caption text--disabled mr-1"
                  v-on="on"
                >
                  {{ project.likes.length }} {{ 'like' | pluralize(project.likes.length) }}
                </span>
              </template>
              <span>
                {{ project.likes.length ? project.likes.map(like => like.user.name).join(', ') : 'No likes... Yet...' }}
              </span>
            </v-tooltip>
            <v-tooltip
              v-if="event.show_document_field"
              :open-delay="500"
              :max-width="500"
              bottom
            >
              <template
                v-slot:activator="{ on }"
              >
                <v-btn
                  class="mr-1"
                  :disabled="!project.document || !project.is_enabled"
                  text
                  fab
                  x-small
                  @click.prevent="openURL(project.document)"
                  v-on="on"
                >
                  <v-icon :color="project.document ? 'primary' : ''">
                    mdi-file-document
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ project.document }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="event.show_github_repository_field"
              :open-delay="500"
              :max-width="500"
              bottom
            >
              <template
                v-slot:activator="{ on }"
              >
                <v-btn
                  class="mr-1"
                  :disabled="!project.github_repository || !project.is_enabled"
                  text
                  fab
                  x-small
                  @click.prevent="openURL(project.github_repository)"
                  v-on="on"
                >
                  <v-icon :color="project.github_repository ? 'primary' : ''">
                    mdi-github
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ project.github_repository }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="event.show_slack_channel_field"
              :open-delay="500"
              :max-width="500"
              bottom
            >
              <template
                v-slot:activator="{ on }"
              >
                <v-btn
                  class="mr-1"
                  :disabled="!project.slack_channel || !project.is_enabled"
                  text
                  fab
                  x-small
                  @click.prevent="openURL(`https://slack.com/app_redirect?channel=${project.slack_channel}`)"
                  v-on="on"
                >
                  <v-icon :color="project.slack_channel ? 'primary' : ''">
                    mdi-slack
                  </v-icon>
                </v-btn>
              </template>
              <span>Open <strong>{{ `#${project.slack_channel}` }}</strong> in Slack</span>
            </v-tooltip>
            <v-tooltip
              v-if="event.show_video_field"
              :open-delay="500"
              :max-width="500"
              bottom
            >
              <template
                v-slot:activator="{ on }"
              >
                <v-btn
                  class="mr-1"
                  :disabled="!project.video || !project.is_enabled"
                  text
                  fab
                  x-small
                  @click.prevent="openURL(project.video)"
                  v-on="on"
                >
                  <v-icon :color="project.video ? 'primary' : ''">
                    mdi-video
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ project.video }}</span>
            </v-tooltip>
          </div>
        </v-card-actions>
      </v-card>
    </v-hover>
    <example-dialog
      v-if="currentUserIsOwner || currentUserIsEventOwner || currentUserIsEventModerator"
      v-model="editProjectDialog"
      title="Edit Project"
      :tabs="[
        {
          name: 'General',
          component: require('./EditProject/ManageProjectGeneral').default,
          props: {
            event,
            project,
          },
        },
        {
          name: 'Participant Slots',
          component: require('./EditProject/ManageProjectParticipantSlots').default,
          props: {
            event,
            project,
          },
        },
        {
          name: 'Tags',
          component: require('./EditProject/ManageProjectTags').default,
          props: {
            event,
            project,
          },
        },
      ]"
      @change="$emit('change')"
    />
    <example-dialog
      v-model="viewProjectDialog"
      :title="project.name"
      :tabs="[
        {
          name: 'General',
          component: require('@/views/dashboard/components/core/ProjectView').default,
          props: { event, project, dialogMode: true },
        },
      ]"
      :buttons="[
        {
          to: `/event/${event.slug}/${project.random_key}`,
          icon: 'mdi-export',
        }
      ]"
      dense
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ProjectCardParticipantSlots from './ProjectCardParticipantSlots'
import ExampleDialog from '../core/ExampleDialog'
import SlackAvatar from '../core/SlackAvatar'

import colors from 'vuetify/lib/util/colors'

export default {
  name: 'ProjectCard',
  components: { ProjectCardParticipantSlots, ExampleDialog, SlackAvatar },
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: {
      like: false,
      delete: false
    },
    editProjectDialog: false,
    viewProjectDialog: false
  }),
  computed: {
    ...mapState('user', ['claims']),
    // Ownership
    currentUserIsOwner () {
      return this.project.owner.id === this.claims.sub
    },
    currentUserIsEventOwner () {
      return this.event.owner.id === this.claims.sub
    },
    currentUserIsEventModerator () {
      return !!this._.find(this.event.moderators, moderator => moderator.user.id === this.claims.sub)
    },
    // Liking
    currentUserLike () {
      return this._.find(this.project.likes, like => like.user.id === this.claims.sub)
    },
    // BASE_URL
    baseUrl () {
      return window.location.origin
    },
    colors () {
      return colors
    }
  },
  apollo: {
    $subscribe: {
      projectParticipantSlotsUpdated: {
        query: require('@/gql/subscribeProjectParticipantSlotsUpdated').default,
        variables () {
          return {
            project_id: this.project.id
          }
        },
        result ({ data: { projectParticipantSlotsUpdated } }) {
          const client = this.$apollo.getClient()
          const data = client.cache.readQuery({
            query: require('@/gql/getProjects').default,
            variables: { slug: this.$route.params.eventSlug }
          })
          const currentProject = this._.find(data.projects, { id: this.project.id })
          if (!currentProject) return
          currentProject.slots = projectParticipantSlotsUpdated
          client.cache.writeQuery({
            query: require('@/gql/getProjects').default,
            variables: { slug: this.$route.params.eventSlug },
            data
          })
        }
      },
      projectLikesUpdated: {
        query: require('@/gql/subscribeProjectLikesUpdated').default,
        variables () {
          return {
            project_id: this.project.id
          }
        },
        result ({ data: { projectLikesUpdated } }) {
          const client = this.$apollo.getClient()
          const data = client.cache.readQuery({
            query: require('@/gql/getProjects').default,
            variables: { slug: this.$route.params.eventSlug }
          })
          const currentProject = this._.find(data.projects, { id: this.project.id })
          if (!currentProject) return
          currentProject.likes = projectLikesUpdated
          client.cache.writeQuery({
            query: require('@/gql/getProjects').default,
            variables: { slug: this.$route.params.eventSlug },
            data
          })
        }
      }
    }
  },
  methods: {
    editProject () {
      this.editProjectDialog = true
    },
    viewProject () {
      this.viewProjectDialog = true
    },
    async likeMutation () {
      this.loading.like = true
      const currentValue = !!this.currentUserLike
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/updateProjectLike').default,
          variables: {
            project_id: this.project.id,
            is_enabled: !currentValue
          },
          update: (store, { data: { updateProjectLike } }) => {
            const data = store.readQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug } })
            const currentProject = this._.find(data.projects, { id: this.project.id })
            if (!currentProject) return
            currentProject.likes = updateProjectLike
            store.writeQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug }, data })
          }
        })
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'GraphQL Error',
          text: error.message.replace('GraphQL error: ', ''),
          duration: 10000
        })
      }
      this.loading.like = false
    },
    async deleteMutation () {
      const execute = await this.$confirm(
          `
            Do you really want to delete this project?<br /><br />
            <strong class="red--text">This cannot be undone! All related data will be also deleted!</strong>
          `,
          {
            color: 'red',
            icon: 'mdi-alert'
          }
      )
      if (!execute) return
      this.loading.delete = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/deleteProject').default,
          variables: { id: this.project.id },
          update: (store, { data: { deleteProject } }) => {
            const data = store.readQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug } })
            data.projects = data.projects.filter(project => project.id !== deleteProject.id)
            store.writeQuery({ query: require('@/gql/getProjects').default, variables: { slug: this.event.slug }, data })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteProject: {
              __typename: 'Project',
              id: this.project.id
            }
          }
        })
      } catch (error) {
        console.error(error)
      }
      this.loading.delete = false
    },
    openURL (url) {
      if (url) {
        const win = window.open(url, '_blank')
        win.focus()
      }
    }
  }
}
</script>

<style lang="scss">
  .tag-slide-group.v-slide-group {
    height: 16px;
  }
  .tag-slide-group .v-slide-group__next, .v-slide-group__prev {
    flex: 0 1 24px;
    min-width: 24px !important;
  }
  .zoom {
    transition: transform .2s; /* Animation */
  }
  .zoom:hover {
    z-index: 1;
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .white-background {
    background: rgba(255, 255, 255, 0.9);
  }
</style>
