import gql from 'graphql-tag'
import CommentAllFields from './fragments/CommentAllFields'

export default gql`
  subscription feedCommentCreated ($event_id: Int!, $user: String!) {
    feedCommentCreated (event_id: $event_id, user: $user) {
      ...CommentAllFields
    }
  }
  ${CommentAllFields}
`
